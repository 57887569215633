import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { LicenseService } from '../../../modules/backoffice/license/license.service';
import { ModuleGuardData } from '../../../modules/backoffice/license/modules/modules.types';

export const ModuleGuard: CanActivateFn | CanActivateChildFn = (
    route,
    state
) => {
    const router: Router = inject(Router);
    const licenseService = inject(LicenseService);
    const module = <ModuleGuardData>route.data;

    // Check if the module is active
    if (licenseService.isModuleActive(module.moduleName)) {
        return of(true);
    } else {
        let urlTree = router.parseUrl('forbidden');
        return of(urlTree);
    }
};
