import { Component } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoDirective } from '@jsverse/transloco';
import { FuseConfigService, Scheme } from '../../../../@fuse/services/config';

@Component({
    selector: 'theme-picker',
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        TranslocoDirective,
    ],
    templateUrl: './theme-picker.component.html',
    styleUrl: './theme-picker.component.scss',
})
export class ThemePickerComponent {
    constructor(private _fuseConfigService: FuseConfigService) {}

    setActiveThemeMode(scheme: Scheme) {
        this._fuseConfigService.config = { scheme };
    }
}
