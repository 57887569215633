import { Component, OnDestroy, OnInit, input } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import { TranslocoDirective } from '@jsverse/transloco';
import { AlertService } from './alert.service';
import { Alert, AlertIntervalObject, AlertType } from './alert.types';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html',
    animations: fuseAnimations,
    imports: [MatIcon, NgClass, TranslocoDirective],
})
export class AlertComponent implements OnInit, OnDestroy {
    readonly id = input('default-alert');
    readonly fade = input(true);

    alerts: Alert[] = [];
    alertSubscription: Subscription;
    routeSubscription: Subscription;

    private alertTimeouts: Map<Alert, AlertIntervalObject> = new Map();
    protected readonly AlertType = AlertType;

    constructor(
        private router: Router,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        // subscribe to new alert notifications
        this.alertSubscription = this.alertService
            .onAlert(this.id())
            .subscribe((alert) => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    // filter out alerts without 'keepAfterRouteChange' flag
                    this.alerts = this.alerts.filter(
                        (x) => x.keepAfterRouteChange
                    );

                    // remove 'keepAfterRouteChange' flag on the rest
                    this.alerts.forEach((x) => delete x.keepAfterRouteChange);
                    return;
                }

                // add alert to array
                this.alerts.push(alert);

                // auto close alert if required
                if (alert.autoClose) {
                    this.prepareAndStartAlertRemovalCountdown(alert, 6000);
                }
            });

        // clear alerts on location change
        this.routeSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.alertService.clear(this.id());
            }
        });
    }

    ngOnDestroy() {
        // unsubscribe to avoid memory leaks
        this.alertSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    prepareAndStartAlertRemovalCountdown(alert: Alert, duration: number) {
        let alertTimeoutObj = new AlertIntervalObject(null, duration);
        this.startAlertRemovalCountdown(alertTimeoutObj, alert);
        this.alertTimeouts.set(alert, alertTimeoutObj);
    }

    pauseAlertRemovalCountdown(alert: Alert) {
        const alertTimeoutObj = this.alertTimeouts.get(alert);
        if (alertTimeoutObj) {
            clearInterval(alertTimeoutObj.intervalId);
        }
    }

    resumeAlertRemovalCountdown(alert: Alert) {
        const alertTimeoutObj = this.alertTimeouts.get(alert);
        if (alertTimeoutObj) {
            this.startAlertRemovalCountdown(alertTimeoutObj, alert);
        }
    }

    private startAlertRemovalCountdown(
        alertTimeoutObj: AlertIntervalObject,
        alert: Alert
    ) {
        alertTimeoutObj.intervalId = setInterval(() => {
            alertTimeoutObj.remainingTime -= alertTimeoutObj.updateInterval;

            if (alertTimeoutObj.remainingTime <= 0) {
                this.removeAlert(alert);
                clearInterval(alertTimeoutObj.intervalId);
            }
        }, alertTimeoutObj.updateInterval);
    }

    removeAlert(alert: Alert) {
        // check if already removed to prevent error on auto close
        if (!this.alerts.includes(alert)) return;

        if (this.fade()) {
            alert.fadeState = '*';

            // fade out alert
            this.alerts.find((x) => x === alert).fade = true;

            // remove alert after faded out
            setTimeout(() => {
                this.alerts = this.alerts.filter((x) => x !== alert);
            }, 250);
        } else {
            // remove alert
            this.alerts = this.alerts.filter((x) => x !== alert);
        }
    }

    cssClass(alert: Alert) {
        if (!alert) return;

        const classes = [];

        const alertTypeClass = {
            [AlertType.Success]: 'bg-emerald-500 text-white',
            [AlertType.Error]: 'bg-red-500 text-white',
            [AlertType.Info]: 'bg-blue-500 text-white',
            [AlertType.Warning]: 'bg-yellow-500 text-black',
        };

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            alert.fadeState = 'void';
        }

        return classes.join(' ');
    }

    copyErrorText(alert: Alert) {
        //Copy the error message to the clipboard. The text is composed as follows:
        // - the error traceId (if available)
        // - the alert message
        // - the alert details shown as a list (if they exist)
        let textToCopy = '';
        if (alert.traceId) {
            textToCopy += `[TraceId: ${alert.traceId}]\n`;
        }
        textToCopy += alert.message;
        if (alert.details?.length > 0) {
            textToCopy += '\n- ';
            textToCopy += alert.details.join('\n- ');
        }
        if (alert.additionalJsonErrors) {
            textToCopy += '\ndetails: ';
            textToCopy += JSON.stringify(alert.additionalJsonErrors, null, 4);
        }
        navigator.clipboard.writeText(textToCopy);
        alert.copyClicked = true;
    }

    getProgress(alert: Alert) {
        return this.alertTimeouts.get(alert)?.progress ?? 0;
    }
}
