import { inject, Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { TranslocoService } from '@jsverse/transloco';
import { cloneDeep } from 'lodash-es';
import { SsPermissionsClaims } from '../../../core/auth/auth.types';
import { UserService } from '../../../core/user/user.service';
import { LicenseService } from '../../../modules/backoffice/license/license.service';
import { SS_SUPPORTED_MODULES } from '../../../modules/backoffice/license/modules/modules.constants';

@Injectable({ providedIn: 'root' })
export class NavigationMockApi {
    userService = inject(UserService);
    licenseService = inject(LicenseService);
    private readonly _compactNavigation: FuseNavigationItem[] = [
        {
            id: 'home',
            title: 'home',
            type: 'basic',
            icon: 'heroicons_outline:chart-pie',
            link: '/home',
        },
        {
            id: 'configuration',
            title: 'configuration',
            type: 'basic',
            icon: 'heroicons_outline:cog-6-tooth',
            link: '/configuration',
            hidden: () =>
                !this.userService.hasPermission(
                    SsPermissionsClaims.Configuration
                ),
        },
        {
            id: 'basic-master-data',
            title: 'basic-master-data',
            type: 'collapsable',
            icon: 'heroicons_outline:inbox',
            hidden: (masterDataItem) => this.allChildrenHidden(masterDataItem),
            children: [
                {
                    id: 'currencies',
                    title: 'currencies',
                    type: 'basic',
                    icon: 'heroicons_outline:currency-euro',
                    link: '/currencies',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Currencies
                        ),
                },
                {
                    id: 'vats',
                    title: 'vats',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-bar-square',
                    link: '/vats',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Vats
                        ),
                },
                {
                    id: 'meas-units',
                    title: 'meas-units',
                    type: 'basic',
                    icon: 'heroicons_outline:queue-list',
                    link: '/meas-units',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.MeasUnits
                        ),
                },
            ],
        },
        {
            id: 'accounts',
            title: 'accounts-section',
            type: 'collapsable',
            icon: 'heroicons_outline:user-circle',
            hidden: (accountItem) => this.allChildrenHidden(accountItem),
            children: [
                {
                    id: 'roles',
                    title: 'roles',
                    type: 'basic',
                    icon: 'heroicons_outline:rectangle-group',
                    link: '/roles',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Roles
                        ),
                },
                {
                    id: 'users',
                    title: 'users',
                    type: 'basic',
                    icon: 'heroicons_outline:users',
                    link: '/accounts',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Users
                        ),
                },
            ],
        },
        {
            id: 'business-entities-section',
            title: 'business-entities-section',
            type: 'collapsable',
            icon: 'heroicons_outline:building-office',
            hidden: (item) => this.allChildrenHidden(item),
            children: [
                {
                    id: 'companies',
                    title: 'companies',
                    type: 'basic',
                    icon: 'heroicons_outline:building-office-2',
                    link: '/companies',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Companies
                        ),
                },
                {
                    id: 'shops',
                    title: 'shops',
                    type: 'basic',
                    icon: 'heroicons_outline:building-storefront',
                    link: '/shops',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Shops
                        ),
                },
                {
                    id: 'pos',
                    title: 'pos',
                    type: 'basic',
                    icon: 'heroicons_outline:banknotes',
                    link: '/pos',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Pos
                        ),
                },
            ],
        },
        {
            id: 'articles',
            title: 'articles',
            type: 'basic',
            icon: 'heroicons_outline:square-3-stack-3d',
            link: '/articles',
            hidden: () =>
                !this.userService.hasPermission(SsPermissionsClaims.Articles),
        },
        {
            id: 'recipes',
            title: 'recipes',
            type: 'basic',
            icon: 'heroicons_outline:queue-list',
            link: '/recipes',
            hidden: () =>
                !this.userService.hasPermission(SsPermissionsClaims.Recipes),
        },
        {
            id: 'sell-section',
            title: 'sell-section',
            type: 'collapsable',
            icon: 'heroicons_outline:calculator',
            hidden: (item) => this.allChildrenHidden(item),
            children: [
                {
                    id: 'sale-price-lists',
                    title: 'sale-price-lists',
                    type: 'basic',
                    icon: 'heroicons_outline:table-cells',
                    link: '/sale-price-lists',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.SalePriceLists
                        ),
                },
                {
                    id: 'upsells',
                    title: 'upsells',
                    type: 'basic',
                    icon: 'heroicons_outline:receipt-percent',
                    link: '/upsells',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Upsells
                        ),
                },
                {
                    id: 'out-of-stock',
                    title: 'out-of-stock',
                    type: 'basic',
                    icon: 'heroicons_outline:archive-box-x-mark',
                    link: '/out-of-stock',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.OutOfStock
                        ),
                },
            ],
        },
        {
            id: 'kiosk',
            title: 'kiosk.kiosk',
            type: 'collapsable',
            icon: 'heroicons_outline:device-tablet',
            hidden: (item) =>
                this.allChildrenHidden(item) ||
                !this.licenseService.isModuleActive(SS_SUPPORTED_MODULES.KIOSK),
            children: [
                {
                    id: 'kiosk-tile-templates',
                    title: 'kiosk.tile-templates',
                    type: 'basic',
                    icon: 'heroicons_outline:paint-brush',
                    link: '/kiosk/tile-templates',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
                {
                    id: 'kiosk-ui-groups',
                    title: 'kiosk.ui-groups',
                    type: 'basic',
                    icon: 'heroicons_outline:squares-plus',
                    link: '/kiosk/ui-groups',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
                {
                    id: 'kiosk-layouts',
                    title: 'kiosk.layouts',
                    type: 'basic',
                    icon: 'heroicons_outline:rectangle-stack',
                    link: '/kiosk/layouts',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
                {
                    id: 'kiosk-layouts-schedule',
                    title: 'kiosk.layouts-schedule',
                    type: 'basic',
                    icon: 'heroicons_outline:calendar-days',
                    link: '/kiosk/layouts-schedule',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
                {
                    id: 'kiosk-media-schedule',
                    title: 'kiosk.media-schedule',
                    type: 'basic',
                    icon: 'heroicons_outline:photo',
                    link: '/kiosk/media-schedule',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
            ],
        },
        {
            id: 'tags',
            title: 'tags',
            type: 'basic',
            icon: 'heroicons_outline:tag',
            link: '/tags',
            hidden: () =>
                !this.userService.hasPermission(SsPermissionsClaims.Tags),
        },
    ];
    private readonly _defaultNavigation: FuseNavigationItem[] = [
        {
            id: 'home',
            title: 'home',
            type: 'basic',
            icon: 'heroicons_outline:chart-pie',
            link: '/home',
        },
        {
            id: 'configuration',
            title: 'configuration',
            type: 'basic',
            icon: 'heroicons_outline:cog-6-tooth',
            link: '/configuration',
            hidden: () =>
                !this.userService.hasPermission(
                    SsPermissionsClaims.Configuration
                ),
        },
        {
            id: 'basic-master-data',
            title: 'basic-master-data',
            type: 'collapsable',
            icon: 'heroicons_outline:inbox',
            hidden: (masterDataItem) => this.allChildrenHidden(masterDataItem),
            children: [
                {
                    id: 'currencies',
                    title: 'currencies',
                    type: 'basic',
                    icon: 'heroicons_outline:currency-euro',
                    link: '/currencies',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Currencies
                        ),
                },
                {
                    id: 'vats',
                    title: 'vats',
                    type: 'basic',
                    icon: 'heroicons_outline:chart-bar-square',
                    link: '/vats',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Vats
                        ),
                },
                {
                    id: 'meas-units',
                    title: 'meas-units',
                    type: 'basic',
                    icon: 'heroicons_outline:queue-list',
                    link: '/meas-units',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.MeasUnits
                        ),
                },
            ],
        },
        {
            id: 'accounts',
            title: 'accounts-section',
            type: 'collapsable',
            icon: 'heroicons_outline:user-circle',
            hidden: (accountItem) => this.allChildrenHidden(accountItem),
            children: [
                {
                    id: 'roles',
                    title: 'roles',
                    type: 'basic',
                    icon: 'heroicons_outline:rectangle-group',
                    link: '/roles',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Roles
                        ),
                },
                {
                    id: 'users',
                    title: 'users',
                    type: 'basic',
                    icon: 'heroicons_outline:users',
                    link: '/accounts',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Users
                        ),
                },
            ],
        },
        {
            id: 'business-entities-section',
            title: 'business-entities-section',
            type: 'collapsable',
            icon: 'heroicons_outline:building-office',
            hidden: (item) => this.allChildrenHidden(item),
            children: [
                {
                    id: 'companies',
                    title: 'companies',
                    type: 'basic',
                    icon: 'heroicons_outline:building-office-2',
                    link: '/companies',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Companies
                        ),
                },
                {
                    id: 'shops',
                    title: 'shops',
                    type: 'basic',
                    icon: 'heroicons_outline:building-storefront',
                    link: '/shops',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Shops
                        ),
                },
                {
                    id: 'pos',
                    title: 'pos',
                    type: 'basic',
                    icon: 'heroicons_outline:banknotes',
                    link: '/pos',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Pos
                        ),
                },
            ],
        },
        {
            id: 'articles',
            title: 'articles',
            type: 'basic',
            icon: 'heroicons_outline:square-3-stack-3d',
            link: '/articles',
            hidden: () =>
                !this.userService.hasPermission(SsPermissionsClaims.Articles),
        },
        {
            id: 'recipes',
            title: 'recipes',
            type: 'basic',
            icon: 'heroicons_outline:queue-list',
            link: '/recipes',
            hidden: () =>
                !this.userService.hasPermission(SsPermissionsClaims.Recipes),
        },
        {
            id: 'sell-section',
            title: 'sell-section',
            type: 'collapsable',
            icon: 'heroicons_outline:calculator',
            hidden: (item) => this.allChildrenHidden(item),
            children: [
                {
                    id: 'sale-price-lists',
                    title: 'sale-price-lists',
                    type: 'basic',
                    icon: 'heroicons_outline:table-cells',
                    link: '/sale-price-lists',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.SalePriceLists
                        ),
                },
                {
                    id: 'upsells',
                    title: 'upsells',
                    type: 'basic',
                    icon: 'heroicons_outline:receipt-percent',
                    link: '/upsells',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.Upsells
                        ),
                },
                {
                    id: 'out-of-stock',
                    title: 'out-of-stock',
                    type: 'basic',
                    icon: 'heroicons_outline:archive-box-x-mark',
                    link: '/out-of-stock',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.OutOfStock
                        ),
                },
            ],
        },
        {
            id: 'kiosk',
            title: 'kiosk.kiosk',
            type: 'collapsable',
            icon: 'heroicons_outline:device-tablet',
            hidden: (item) =>
                this.allChildrenHidden(item) ||
                !this.licenseService.isModuleActive(SS_SUPPORTED_MODULES.KIOSK),
            children: [
                {
                    id: 'kiosk-tile-templates',
                    title: 'kiosk.tile-templates',
                    type: 'basic',
                    icon: 'heroicons_outline:paint-brush',
                    link: '/kiosk/tile-templates',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
                {
                    id: 'kiosk-ui-groups',
                    title: 'kiosk.ui-groups',
                    type: 'basic',
                    icon: 'heroicons_outline:squares-plus',
                    link: '/kiosk/ui-groups',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
                {
                    id: 'kiosk-layouts',
                    title: 'kiosk.layouts',
                    type: 'basic',
                    icon: 'heroicons_outline:rectangle-stack',
                    link: '/kiosk/layouts',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
                {
                    id: 'kiosk-layouts-schedule',
                    title: 'kiosk.layouts-schedule',
                    type: 'basic',
                    icon: 'heroicons_outline:calendar-days',
                    link: '/kiosk/layouts-schedule',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
                {
                    id: 'kiosk-media-schedule',
                    title: 'kiosk.media-schedule',
                    type: 'basic',
                    icon: 'heroicons_outline:photo',
                    link: '/kiosk/media-schedule',
                    hidden: () =>
                        !this.userService.hasPermission(
                            SsPermissionsClaims.KioskApplicationData
                        ),
                },
            ],
        },
        {
            id: 'tags',
            title: 'tags',
            type: 'basic',
            icon: 'heroicons_outline:tag',
            link: '/tags',
            hidden: () =>
                !this.userService.hasPermission(SsPermissionsClaims.Tags),
        },
    ];

    /**
     * Constructor
     */
    constructor(
        private _fuseMockApiService: FuseMockApiService,
        private _translocoService: TranslocoService
    ) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    get defaultNavigation() {
        return this._defaultNavigation;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
            // Fill compact navigation children using the default navigation
            this._compactNavigation.forEach((compactNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === compactNavItem.id) {
                        compactNavItem.children = cloneDeep(
                            defaultNavItem.children
                        );
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    //clonedeep
                    compact: this._compactNavigation.map((a) => {
                        return {
                            ...a,
                            title: this._translocoService.translate(
                                'navigation.' + a.title
                            ),
                            children: a.children?.map((c) => {
                                return {
                                    ...c,
                                    title: this._translocoService.translate(
                                        'navigation.' + c.title
                                    ),
                                };
                            }),
                        };
                    }),
                    default: this._defaultNavigation.map((a) => {
                        return {
                            ...a,
                            title: this._translocoService.translate(
                                'navigation.' + a.title
                            ),
                            children: a.children?.map((c) => {
                                return {
                                    ...c,
                                    title: this._translocoService.translate(
                                        'navigation.' + c.title
                                    ),
                                };
                            }),
                        };
                    }),
                },
            ];
        });
    }

    allChildrenHidden(item: FuseNavigationItem) {
        return item.children.every((x) => x.hidden && x.hidden(x));
    }
}
