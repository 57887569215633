import { Component } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { MatTooltip } from '@angular/material/tooltip';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'wiki',
    imports: [MatIcon, MatIconButton, TranslocoDirective, MatTooltip],
    templateUrl: './wiki.component.html',
})
export class WikiComponent {
    openWiki() {
        window.open('https://wiki.stockstore.cloud', '_blank');
    }
}
