<div class="fixed right-5 top-10 left-5 sm:left-auto z-9999" *transloco="let t">
    @for (alert of alerts; track alert) {
        <div class="flex flex-col mb-4 {{ cssClass(alert) }} rounded-lg">
            <div
                id="alert-1"
                class="flex p-4 min-w-72"
                (mouseenter)="pauseAlertRemovalCountdown(alert)"
                (mouseleave)="resumeAlertRemovalCountdown(alert)"
                role="alert"
                @fadeIn
                [@fadeOut]="alert.fadeState"
            >
                <svg
                    aria-hidden="true"
                    class="h-5 w-5 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
                <span class="sr-only">Info</span>
                <div class="ml-3 flex flex-col items-start flex-grow">
                    @if (alert.title) {
                        <div class="text-lg font-bold">{{ alert.title }}</div>
                    }
                    <div
                        class="text-md font-medium"
                        [innerHTML]="alert.message"
                    ></div>
                    @if (alert.details?.length > 0) {
                        <ul class="list-disc ml-4 text-md">
                            @for (detail of alert.details; track detail) {
                                <li>
                                    {{ detail }}
                                </li>
                            }
                        </ul>
                    }
                    <!--Show a button that copies the error text if the alert is of type error-->
                    @if (alert.type == AlertType.Error) {
                        <div class="flex flex-row items-center relative ml-auto left-8">
                            <button
                                type="button"
                                class="mt-2 text-sm text-white rounded-md hover:bg-transparent/10 px-2 py-1 flex flex-row items-center"
                                (click)="copyErrorText(alert)"
                            >
                                <mat-icon svgIcon="mat_outline:content_copy"
                                          class="text-white scale-[65%] mr-1"></mat-icon>
                                @if (!alert.copyClicked) {
                                    <p class="font-light">
                                        {{ t('alerts.copy-error-message') }}
                                    </p>
                                }
                                <p [ngClass]="alert.copyClicked ? 'opacity-100' : 'opacity-0 max-h-0 max-w-0'"
                                   class="transition-all ease-in font-light">
                                    {{ t('alerts.copied') }}
                                </p>
                            </button>
                        </div>
                    }
                </div>
                <button
                    type="button"
                    class="ml-auto -mx-1.5 -my-1.5 {{
                    cssClass(alert)
                }} rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8"
                    (click)="removeAlert(alert)"
                >
                    <span class="sr-only">Close</span>
                    <svg
                        aria-hidden="true"
                        class="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </button>
            </div>
            <div class="h-1.5 rounded-bl-lg bg-white/80 transition-all ease-linear"
                 [style.width.%]="getProgress(alert)"
                 @fadeIn
                 [@fadeOut]="alert.fadeState"></div>
        </div>
    }
</div>
